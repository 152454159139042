:root
{
	--primary: #b7193f;
	--secondary: #7b1430;
	--gold: #B0976D;
	--redWine: #722F37;
	--whiteWine: #EEEDC4;
}

.primary
{
	color: var(--primary) !important;
}

.secondary
{
	color: var(--secondary) !important;
}

.gold
{
	color: var(--gold) !important;
}

.red-wine
{
	color: var(--redWine) !important;
}

.white-wine
{
	color: var(--whiteWine) !important;
}

.background-primary
{
	background: var(--primary) !important;
}

.background-secondary
{
	background: var(--secondary) !important;
}

.background-gold
{
	background: var(--gold) !important;
}

.background-red-wine
{
	background: var(--redWine) !important;
}

.background-white-wine
{
	background: var(--whiteWine) !important;
}