/* Init */
.public .home .init
{
	background: url('/public/assets/illustration.jpg') no-repeat;
	background-size: 100%;
}

@media screen and (max-width: 768px)
{
	.public .home .init
	{
		background-size: cover;
	}
}

.public .home .init .hello
{
	padding: 50px 25px;
}

@media screen and (min-width: 768px)
{
	.public .home .init .hello
	{
		height: 100%;
	}
}

.public .home .init .hello .crack-ux-text
{
	font-weight: bold;
}

@media screen and (min-width: 768px)
{
	.public .home .init .hello .crack-ux-body
	{
		position: absolute !important;
		top: 50% !important;
		transform: translate(0%, -50%) !important;
	}
}

.public .home .init .hello .crack-ux-body .crack-ux-text
{
	font-size: 1em;
}

@media screen and (min-width: 768px)
{
	.public .home .init .hello .crack-ux-body .crack-ux-text
	{
		font-size: 1.5em;
	}
}

@media screen and (min-width: 768px)
{
	.public .home .init .video-container
	{
		height: 100vh;
	}
}

.public .home .init .video
{
	width: calc(100% - 30px);
	margin: 0px auto;
	background-image: linear-gradient(145deg, #411722, #c4915e);
	border-radius: 7px;
}

@media screen and (min-width: 768px)
{
	.public .home .init .video
	{
		width: 360px;
		margin-top: 50px;
		position: absolute !important;
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%) !important;
	}
}

@media screen and (min-width: 1372px)
{
	.public .home .init .video
	{
		margin-top: -30px;
		width: 480px;
	}
}

.public .home .init .video video
{
	width: 100%;
}

/* Wellcome */
.public .welcome
{
	margin-top: 350px;
	padding: 25px 0px;
}

@media screen and (min-width: 768px)
{
	.public .welcome
	{
		margin-top: 50px;
		padding: 125px 50px 50px;
	}
}

.public .welcome .message
{
	background-image: url('/public/assets/background-grapes.jpg');
	background-color: #f6f6f6; /* color de fondo */
	background-blend-mode: lighten; /* modo de mezcla */
	background-position: center;
	padding: 50px 0px;
}

@media screen and (min-width: 768px)
{
	.public .welcome .message
	{
		background: none;
		float: right;
		padding: 50px 0px 0px 50px;
	}
}

.public .welcome .message .crack-ux-text
{
	font-size: 1em;
	text-align: center;
}

@media screen and (min-width: 768px)
{
	.public .welcome .message .crack-ux-header .crack-ux-text
	{
		padding: 0px 15%;
	}

	.public .welcome .message .crack-ux-body .crack-ux-text
	{
		text-align: left;
		text-align: justify;
	}

	.public .welcome .message .crack-ux-footer .crack-ux-text
	{
		padding: 0px 15%;
	}
}

.public .welcome .image
{
	height: 230px;
}

@media screen and (min-width: 768px)
{
	.public .welcome .image
	{
		height: 568px;
		vertical-align: top;
	}
}

/* Events */


/* Gallery */
.public .gallery .media
{
	height: 280px;
}

@media screen and (min-width: 768px)
{
	.public .gallery .media
	{
		height: 290px;
	}

	.public .gallery .media::-webkit-scrollbar
	{
		width: 5px;
	}

	.public .gallery .media::-webkit-scrollbar-track
	{
		background-color: var(--gold);
	}

	.public .gallery .media::-webkit-scrollbar-thumb
	{
		background-color: var(--secondary);
		border-radius: 5px;
	}
}

.public .gallery .media img
{
	height: 280px;
}

/* Contact Us */
.public .contact-us .form
{
	width: 100%;
}

@media screen and (min-width: 768px)
{
	.public .contact-us .form
	{
		position: absolute !important;
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%) !important;
	}
}

/* End Page */
.public .end-page
{
	background-color: #232323;
	color: #aaaaaa;
}

@media screen and (min-width: 768px)
{
	.public .end-page .powered
	{
		float: right;
	}
}