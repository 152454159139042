/* General */
.public .title
{
	font-size: 24px;
	color: var(--secondary);
	font-weight: bold;
	padding: 25px;
	text-transform: uppercase;
}

.public .subtitle
{
	font-size: 24px;
	color: #232323;
}

.public .section:nth-child(even)
{
	background: #ffffff;
}

.public .section:nth-child(odd)
{
	background: #f1f1f1;
}

/* Specific */
.public > .header
{
	background-color: var(--secondary);
	padding: 10px 15px;
}

@media screen and (min-width: 768px)
{
	.public > .header
	{
		background-color: transparent;
		padding: 5px 35px;
		transition: background-color 0.5s ease-out;
	}

	.public > .header.scrolled
	{
		background-color: var(--secondary);
	}
}

.public > .header .logo
{
	width: 64px;
}

@media screen and (min-width: 768px)
{
	.public > .header .logo
	{
		width: 100px;
	}
}

.public > .header .menu-icon
{
	position: absolute;
	color: var(--whiteWine);
	top: 15px;
	right: 15px;
}

@media screen and (min-width: 768px)
{
	.public > .header .menu-icon
	{
		display: none;
	}	
}

.public > .header > .menu .crack-ux-button .crack-ux-text
{
	color: var(--whiteWine);
	transition: color 0.30s;
	font-size: 16px;
}

.public > .header > .menu
{
	display: block;
	background-color: var(--secondary);
	transition: height 0.3s;
	overflow: hidden;
}

.public > .header > .menu .crack-ux-button
{
	width: 100%;
}

@media screen and (min-width: 768px)
{
	.public > .header > .menu
	{
		background-color: transparent;
		display: inline-block;
		margin-top: 0px;
	}
}

.public > .header > .menu.show
{
	height: calc(100vh - 88px);
	margin-top: 50px;
}

@media screen and (min-width: 768px)
{
	.public > .header > .menu.show
	{
		height: auto;
		margin-top: 0px;
	}
}

.public > .header > .menu.hide
{
	height: 0px;
}

@media screen and (min-width: 768px)
{
	.public > .header > .menu.hide
	{
		height: auto;
	}
}

.public > .header > .menu .crack-ux-button
{
	display: block;
	width: 100%;
	margin-left: 0px;
}

@media screen and (min-width: 768px)
{
	.public > .header > .menu .crack-ux-button
	{
		display: inline-block;
		width: auto;
	}
}

.public > .header > .menu .crack-ux-button .crack-ux-text
{
	padding-bottom: 5px;
}

.public > .header > .menu .crack-ux-button:hover .crack-ux-text
{
	color: var(--primary);
	border-bottom: solid 1px var(--primary);
}