@font-face {
  font-family: 'crack';
  src: url('../font/crack.eot?62281839');
  src: url('../font/crack.eot?62281839#iefix') format('embedded-opentype'),
       url('../font/crack.woff2?62281839') format('woff2'),
       url('../font/crack.woff?62281839') format('woff'),
       url('../font/crack.ttf?62281839') format('truetype'),
       url('../font/crack.svg?62281839#crack') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'crack';
    src: url('../font/crack.svg?62281839#crack') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "crack";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-login:before { content: '\e800'; } /* '' */
.icon-logout:before { content: '\e801'; } /* '' */
.icon-heart:before { content: '\e802'; } /* '' */
.icon-heart-empty:before { content: '\e803'; } /* '' */
.icon-star:before { content: '\e804'; } /* '' */
.icon-star-empty:before { content: '\e805'; } /* '' */
.icon-user:before { content: '\e806'; } /* '' */
.icon-users:before { content: '\e807'; } /* '' */
.icon-mail:before { content: '\e808'; } /* '' */
.icon-search:before { content: '\e809'; } /* '' */
.icon-picture:before { content: '\e80a'; } /* '' */
.icon-ok:before { content: '\e80b'; } /* '' */
.icon-cancel:before { content: '\e80c'; } /* '' */
.icon-plus:before { content: '\e80d'; } /* '' */
.icon-minus:before { content: '\e80e'; } /* '' */
.icon-help-circled:before { content: '\e80f'; } /* '' */
.icon-info-circled:before { content: '\e810'; } /* '' */
.icon-gift:before { content: '\e811'; } /* '' */
.icon-lock:before { content: '\e812'; } /* '' */
.icon-lock-open:before { content: '\e813'; } /* '' */
.icon-eye:before { content: '\e814'; } /* '' */
.icon-eye-off:before { content: '\e815'; } /* '' */
.icon-link:before { content: '\e816'; } /* '' */
.icon-tag:before { content: '\e817'; } /* '' */
.icon-tags:before { content: '\e818'; } /* '' */
.icon-comment:before { content: '\e819'; } /* '' */
.icon-chat:before { content: '\e81a'; } /* '' */
.icon-attention:before { content: '\e81b'; } /* '' */
.icon-attention-circled:before { content: '\e81c'; } /* '' */
.icon-trash-empty:before { content: '\e81d'; } /* '' */
.icon-doc:before { content: '\e81e'; } /* '' */
.icon-folder:before { content: '\e81f'; } /* '' */
.icon-folder-open:before { content: '\e820'; } /* '' */
.icon-phone:before { content: '\e821'; } /* '' */
.icon-cog:before { content: '\e822'; } /* '' */
.icon-cog-alt:before { content: '\e823'; } /* '' */
.icon-calendar:before { content: '\e824'; } /* '' */
.icon-volume-off:before { content: '\e825'; } /* '' */
.icon-volume-down:before { content: '\e826'; } /* '' */
.icon-volume-up:before { content: '\e827'; } /* '' */
.icon-clock:before { content: '\e828'; } /* '' */
.icon-block:before { content: '\e829'; } /* '' */
.icon-down-dir:before { content: '\e82a'; } /* '' */
.icon-up-dir:before { content: '\e82b'; } /* '' */
.icon-left-dir:before { content: '\e82c'; } /* '' */
.icon-right-dir:before { content: '\e82d'; } /* '' */
.icon-zoom-in:before { content: '\e82e'; } /* '' */
.icon-zoom-out:before { content: '\e82f'; } /* '' */
.icon-cw:before { content: '\e830'; } /* '' */
.icon-ccw:before { content: '\e831'; } /* '' */
.icon-arrows-cw:before { content: '\e832'; } /* '' */
.icon-shuffle:before { content: '\e833'; } /* '' */
.icon-play:before { content: '\e834'; } /* '' */
.icon-stop:before { content: '\e835'; } /* '' */
.icon-pause:before { content: '\e836'; } /* '' */
.icon-to-end:before { content: '\e837'; } /* '' */
.icon-to-end-alt:before { content: '\e838'; } /* '' */
.icon-to-start:before { content: '\e839'; } /* '' */
.icon-to-start-alt:before { content: '\e83a'; } /* '' */
.icon-fast-fw:before { content: '\e83b'; } /* '' */
.icon-fast-bw:before { content: '\e83c'; } /* '' */
.icon-eject:before { content: '\e83d'; } /* '' */
.icon-globe:before { content: '\e83e'; } /* '' */
.icon-inbox:before { content: '\e83f'; } /* '' */
.icon-cloud:before { content: '\e840'; } /* '' */
.icon-flight:before { content: '\e841'; } /* '' */
.icon-off:before { content: '\e842'; } /* '' */
.icon-check:before { content: '\e843'; } /* '' */
.icon-chart-bar:before { content: '\e844'; } /* '' */
.icon-credit-card:before { content: '\e845'; } /* '' */
.icon-code-1:before { content: '\e846'; } /* '' */
.icon-box-1:before { content: '\e847'; } /* '' */
.icon-qrcode:before { content: '\e848'; } /* '' */
.icon-barcode:before { content: '\e849'; } /* '' */
.icon-language:before { content: '\e84a'; } /* '' */
.icon-resize-full:before { content: '\e84b'; } /* '' */
.icon-resize-small:before { content: '\e84c'; } /* '' */
.icon-dot:before { content: '\e84d'; } /* '' */
.icon-dot-2:before { content: '\e84e'; } /* '' */
.icon-dot-3:before { content: '\e84f'; } /* '' */
.icon-clipboard:before { content: '\e850'; } /* '' */
.icon-restaurant:before { content: '\e851'; } /* '' */
.icon-chrome-1:before { content: '\e852'; } /* '' */
.icon-firefox-1:before { content: '\e853'; } /* '' */
.icon-opera-1:before { content: '\e854'; } /* '' */
.icon-spin1:before { content: '\e855'; } /* '' */
.icon-spin3:before { content: '\e856'; } /* '' */
.icon-spin2:before { content: '\e857'; } /* '' */
.icon-spin4:before { content: '\e858'; } /* '' */
.icon-spin5:before { content: '\e859'; } /* '' */
.icon-spin6:before { content: '\e85a'; } /* '' */
.icon-download:before { content: '\e85b'; } /* '' */
.icon-upload:before { content: '\e85c'; } /* '' */
.icon-location:before { content: '\e85d'; } /* '' */
.icon-swimming:before { content: '\e85e'; } /* '' */
.icon-flash:before { content: '\e85f'; } /* '' */
.icon-rain:before { content: '\e860'; } /* '' */
.icon-comment-1:before { content: '\e861'; } /* '' */
.icon-comment-alt:before { content: '\e862'; } /* '' */
.icon-chat-1:before { content: '\e863'; } /* '' */
.icon-th-list:before { content: '\e864'; } /* '' */
.icon-key:before { content: '\e865'; } /* '' */
.icon-home:before { content: '\e866'; } /* '' */
.icon-home-circled:before { content: '\e867'; } /* '' */
.icon-install:before { content: '\e868'; } /* '' */
.icon-down:before { content: '\e869'; } /* '' */
.icon-left:before { content: '\e86a'; } /* '' */
.icon-right:before { content: '\e86b'; } /* '' */
.icon-up:before { content: '\e86c'; } /* '' */
.icon-road:before { content: '\e86d'; } /* '' */
.icon-picture-1:before { content: '\e86e'; } /* '' */
.icon-bell:before { content: '\e86f'; } /* '' */
.icon-pencil:before { content: '\e870'; } /* '' */
.icon-emo-happy:before { content: '\e871'; } /* '' */
.icon-trash-2:before { content: '\e872'; } /* '' */
.icon-emo-wink:before { content: '\e873'; } /* '' */
.icon-emo-wink2:before { content: '\e874'; } /* '' */
.icon-emo-unhappy:before { content: '\e875'; } /* '' */
.icon-emo-sleep:before { content: '\e876'; } /* '' */
.icon-emo-thumbsup:before { content: '\e877'; } /* '' */
.icon-emo-devil:before { content: '\e878'; } /* '' */
.icon-emo-surprised:before { content: '\e879'; } /* '' */
.icon-emo-tongue:before { content: '\e87a'; } /* '' */
.icon-emo-coffee:before { content: '\e87b'; } /* '' */
.icon-emo-sunglasses:before { content: '\e87c'; } /* '' */
.icon-emo-displeased:before { content: '\e87d'; } /* '' */
.icon-emo-beer:before { content: '\e87e'; } /* '' */
.icon-emo-grin:before { content: '\e87f'; } /* '' */
.icon-emo-angry:before { content: '\e880'; } /* '' */
.icon-emo-saint:before { content: '\e881'; } /* '' */
.icon-emo-cry:before { content: '\e882'; } /* '' */
.icon-emo-shoot:before { content: '\e883'; } /* '' */
.icon-emo-squint:before { content: '\e884'; } /* '' */
.icon-emo-laugh:before { content: '\e885'; } /* '' */
.icon-wallet:before { content: '\e886'; } /* '' */
.icon-money:before { content: '\e887'; } /* '' */
.icon-vcard:before { content: '\e888'; } /* '' */
.icon-mobile-1:before { content: '\e889'; } /* '' */
.icon-edit:before { content: '\e88a'; } /* '' */
.icon-clipboard-1:before { content: '\e88b'; } /* '' */
.icon-down-open:before { content: '\e88c'; } /* '' */
.icon-left-open:before { content: '\e88d'; } /* '' */
.icon-right-open:before { content: '\e88e'; } /* '' */
.icon-up-open:before { content: '\e88f'; } /* '' */
.icon-align-left:before { content: '\e890'; } /* '' */
.icon-align-right:before { content: '\e891'; } /* '' */
.icon-align-center:before { content: '\e892'; } /* '' */
.icon-align-justify:before { content: '\e893'; } /* '' */
.icon-book-open:before { content: '\e894'; } /* '' */
.icon-headphones-1:before { content: '\e895'; } /* '' */
.icon-videocam:before { content: '\e896'; } /* '' */
.icon-basket:before { content: '\e897'; } /* '' */
.icon-color-adjust:before { content: '\f020'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-check-empty:before { content: '\f096'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-tasks:before { content: '\f0ae'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-list-bullet:before { content: '\f0ca'; } /* '' */
.icon-sort:before { content: '\f0dc'; } /* '' */
.icon-sort-down:before { content: '\f0dd'; } /* '' */
.icon-sort-up:before { content: '\f0de'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-gauge:before { content: '\f0e4'; } /* '' */
.icon-comment-empty:before { content: '\f0e5'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-sitemap:before { content: '\f0e8'; } /* '' */
.icon-exchange:before { content: '\f0ec'; } /* '' */
.icon-download-cloud:before { content: '\f0ed'; } /* '' */
.icon-upload-cloud:before { content: '\f0ee'; } /* '' */
.icon-user-md:before { content: '\f0f0'; } /* '' */
.icon-stethoscope:before { content: '\f0f1'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
.icon-coffee:before { content: '\f0f4'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-building:before { content: '\f0f7'; } /* '' */
.icon-medkit:before { content: '\f0fa'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-desktop:before { content: '\f108'; } /* '' */
.icon-laptop:before { content: '\f109'; } /* '' */
.icon-tablet:before { content: '\f10a'; } /* '' */
.icon-mobile:before { content: '\f10b'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-folder-empty:before { content: '\f114'; } /* '' */
.icon-folder-open-empty:before { content: '\f115'; } /* '' */
.icon-gamepad:before { content: '\f11b'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-fork:before { content: '\f126'; } /* '' */
.icon-unlink:before { content: '\f127'; } /* '' */
.icon-help:before { content: '\f128'; } /* '' */
.icon-info:before { content: '\f129'; } /* '' */
.icon-attention-alt:before { content: '\f12a'; } /* '' */
.icon-mic:before { content: '\f130'; } /* '' */
.icon-mute:before { content: '\f131'; } /* '' */
.icon-calendar-empty:before { content: '\f133'; } /* '' */
.icon-rocket:before { content: '\f135'; } /* '' */
.icon-html5:before { content: '\f13b'; } /* '' */
.icon-css3:before { content: '\f13c'; } /* '' */
.icon-lock-open-alt:before { content: '\f13e'; } /* '' */
.icon-ellipsis-vert:before { content: '\f142'; } /* '' */
.icon-ticket:before { content: '\f145'; } /* '' */
.icon-level-up:before { content: '\f148'; } /* '' */
.icon-level-down:before { content: '\f149'; } /* '' */
.icon-pencil-squared:before { content: '\f14b'; } /* '' */
.icon-doc-inv:before { content: '\f15b'; } /* '' */
.icon-doc-text-inv:before { content: '\f15c'; } /* '' */
.icon-youtube:before { content: '\f167'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-apple:before { content: '\f179'; } /* '' */
.icon-windows:before { content: '\f17a'; } /* '' */
.icon-android:before { content: '\f17b'; } /* '' */
.icon-linux:before { content: '\f17c'; } /* '' */
.icon-sun:before { content: '\f185'; } /* '' */
.icon-moon:before { content: '\f186'; } /* '' */
.icon-box:before { content: '\f187'; } /* '' */
.icon-dot-circled:before { content: '\f192'; } /* '' */
.icon-wheelchair:before { content: '\f193'; } /* '' */
.icon-building-filled:before { content: '\f1ad'; } /* '' */
.icon-database:before { content: '\f1c0'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-word:before { content: '\f1c2'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-file-powerpoint:before { content: '\f1c4'; } /* '' */
.icon-file-image:before { content: '\f1c5'; } /* '' */
.icon-file-audio:before { content: '\f1c7'; } /* '' */
.icon-file-video:before { content: '\f1c8'; } /* '' */
.icon-file-code:before { content: '\f1c9'; } /* '' */
.icon-codeopen:before { content: '\f1cb'; } /* '' */
.icon-lifebuoy:before { content: '\f1cd'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-paper-plane-empty:before { content: '\f1d9'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-share:before { content: '\f1e0'; } /* '' */
.icon-paypal:before { content: '\f1ed'; } /* '' */
.icon-cc-visa:before { content: '\f1f0'; } /* '' */
.icon-cc-mastercard:before { content: '\f1f1'; } /* '' */
.icon-cc-paypal:before { content: '\f1f4'; } /* '' */
.icon-bell-off:before { content: '\f1f6'; } /* '' */
.icon-bell-off-empty:before { content: '\f1f7'; } /* '' */
.icon-trash:before { content: '\f1f8'; } /* '' */
.icon-chart-area:before { content: '\f1fe'; } /* '' */
.icon-chart-pie:before { content: '\f200'; } /* '' */
.icon-chart-line:before { content: '\f201'; } /* '' */
.icon-toggle-off:before { content: '\f204'; } /* '' */
.icon-toggle-on:before { content: '\f205'; } /* '' */
.icon-connectdevelop:before { content: '\f20e'; } /* '' */
.icon-facebook-official:before { content: '\f230'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-user-plus:before { content: '\f234'; } /* '' */
.icon-user-times:before { content: '\f235'; } /* '' */
.icon-battery-4:before { content: '\f240'; } /* '' */
.icon-battery-3:before { content: '\f241'; } /* '' */
.icon-battery-2:before { content: '\f242'; } /* '' */
.icon-battery-1:before { content: '\f243'; } /* '' */
.icon-battery-0:before { content: '\f244'; } /* '' */
.icon-sticky-note:before { content: '\f249'; } /* '' */
.icon-sticky-note-o:before { content: '\f24a'; } /* '' */
.icon-safari:before { content: '\f267'; } /* '' */
.icon-chrome:before { content: '\f268'; } /* '' */
.icon-firefox:before { content: '\f269'; } /* '' */
.icon-opera:before { content: '\f26a'; } /* '' */
.icon-television:before { content: '\f26c'; } /* '' */
.icon-calendar-plus-o:before { content: '\f271'; } /* '' */
.icon-calendar-minus-o:before { content: '\f272'; } /* '' */
.icon-calendar-times-o:before { content: '\f273'; } /* '' */
.icon-calendar-check-o:before { content: '\f274'; } /* '' */
.icon-commenting:before { content: '\f27a'; } /* '' */
.icon-commenting-o:before { content: '\f27b'; } /* '' */
.icon-edge:before { content: '\f282'; } /* '' */
.icon-credit-card-alt:before { content: '\f283'; } /* '' */
.icon-usb:before { content: '\f287'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-user-circle-o:before { content: '\f2be'; } /* '' */
.icon-thermometer:before { content: '\f2c7'; } /* '' */
.icon-thermometer-3:before { content: '\f2c8'; } /* '' */
.icon-thermometer-2:before { content: '\f2c9'; } /* '' */
.icon-thermometer-quarter:before { content: '\f2ca'; } /* '' */
.icon-thermometer-0:before { content: '\f2cb'; } /* '' */
.icon-window-maximize:before { content: '\f2d0'; } /* '' */
.icon-window-minimize:before { content: '\f2d1'; } /* '' */
.icon-window-restore:before { content: '\f2d2'; } /* '' */
.icon-window-close:before { content: '\f2d3'; } /* '' */
.icon-window-close-o:before { content: '\f2d4'; } /* '' */
.icon-twitter-squared:before { content: '\f304'; } /* '' */
.icon-linkedin-squared:before { content: '\f30c'; } /* '' */
.icon-spread:before { content: '\f527'; } /* '' */
